import React, { useState, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Alert,
  Button,
  Image,
  Dropdown,
  Spinner,
} from "react-bootstrap";
import { AccountInfoContext } from "../Context/AccountInfo.js";

import "../App.css";
import logo from "../images/LogoTokcunts.png";
import goldCunt from "../images/GoldCunt.png";
import shitCunt from "../images/ShitCoin.png";

function Claim() {
  let accountInfo = useContext(AccountInfoContext);
  const [alert, setAlert] = useState({
    active: false,
    content: null,
    variant: null,
  });
  const [selection, setSelection] = useState("Select a Tokcunt to circulate");
  const [tokensSelection, setTokensSelection] = useState([]);

  function displayAlert(message, variant) {
    setAlert({ active: true, content: message, variant: variant });
    setTimeout(function () {
      setAlert({ active: false, content: null, variant: null });
    }, 10000);
  }

  function renderAlert() {
    if (alert.active) {
      return (
        <Col className="m-2">
          <Alert variant={alert.variant}>{alert.content}</Alert>
        </Col>
      );
    }
  }

  function selectToken(nft) {
    let newSelection = [...tokensSelection];
    if (tokensSelection.includes(nft.tokenId)) {
      const index = newSelection.indexOf(nft.tokenId);
      newSelection.splice(index, 1);
    } else {
      let arrayLimit = selection.toLowerCase() === "shit" ? 3 : 11;
      if (newSelection.length >= arrayLimit) {
        newSelection.shift();
      }
      newSelection.push(nft.tokenId);
    }
    setTokensSelection(newSelection);
  }

  function renderNFTs() {
    if (
      selection.toLowerCase() === "diamond" ||
      selection.toLowerCase() === "shit"
    ) {
      if (accountInfo.nftsLoaded) {
        return accountInfo.nfts.map((nft, index) => {
          console.log(nft);
          let isCirculated = false;
          nft.rawMetadata.attributes.map((attribute, i) => {
            if (attribute.trait_type === "Circulated") {
              isCirculated = attribute.value === "True" ? true : false;
            }
            return null;
          });
          if (!isCirculated) {
            let highlighted = tokensSelection.includes(nft.tokenId)
              ? "highlighted"
              : null;
            return (
              <Col className="mb-2" key={index}>
                <Image
                  src={nft.media[0].thumbnail}
                  height="100px"
                  width="100px"
                  className={`grow ${highlighted}`}
                  onClick={() => selectToken(nft)}
                ></Image>
              </Col>
            );
          } else {
            return null;
          }
        });
      } else {
        return <div>Loading your collection</div>;
      }
    }
  }

  const handleSelect = (data) => {
    setSelection(data);
  };

  function renderClaimButton() {
    if (
      selection.toLowerCase() !== "shit" &&
      selection.toLowerCase() !== "diamond"
    ) {
      return null;
    } else {
      let disabled = false;
      if (
        selection.toLowerCase() === "diamond" &&
        tokensSelection.length !== 11
      ) {
        disabled = true;
      }
      if (selection.toLowerCase() === "shit" && tokensSelection.length !== 3) {
        disabled = true;
      }
      return (
        <Button id="mint_button" disabled={disabled} onClick={handleClaim}>
          {`Get a ${
            selection.toLowerCase() === "shit" ? "shitcoin" : "diamond coin"
          }`}
        </Button>
      );
    }
  }

  function renderExplanations() {
    if (
      selection.toLowerCase() !== "shit" &&
      selection.toLowerCase() !== "diamond"
    ) {
      return null;
    } else {
      return `Select ${
        selection.toLowerCase() === "shit" ? 3 : 11
      } uncirculated tokcunts`;
    }
  }

  function renderShitOption() {
    if (accountInfo.shitUnlocked) {
      return (
        <Dropdown.Item eventKey="Shit" key="1">
          Shit
        </Dropdown.Item>
      );
    } else {
      return null;
    }
  }

  function renderDiamondOption() {
    if (accountInfo.shitUnlocked) {
      return (
        <Dropdown.Item eventKey="Diamond" key="2">
          Diamond
        </Dropdown.Item>
      );
    } else {
      return null;
    }
  }

  function renderUserInterface() {
    if (!accountInfo.account) {
      return <div>Please connect your wallet</div>;
    }
    if (accountInfo.dropOpened) {
      return (
        <>
          <Row xs={12} className="mt-5">
            <Col
              xs={12}
              className="d-flex justify-content-center align-items-center"
            >
              <Dropdown
                onSelect={handleSelect}
                className="tokcunt_selector mb-2"
              >
                <Dropdown.Toggle
                  variant="light"
                  className="tokcunt_selector"
                  id="dropdown-basic"
                >
                  {selection}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {renderShitOption()}
                  {renderDiamondOption()}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
          <Row xs={12} className="mb-5">
            <Col className="d-flex justify-content-center align-items-center">
              {renderExplanations()}
            </Col>
          </Row>
          <Row xs={12} className="d-flex mt-2">
            {renderNFTs()}
          </Row>
          <Row xs={12} className="mt-2">
            <Col xs={12}>{renderClaimButton()}</Col>
          </Row>
        </>
      );
    } else {
      return null;
    }
  }

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  async function handleClaim() {
    try {
      accountInfo.updateAccountInfo({ userFeedback: "Claiming..." });
      if (selection.toLowerCase() === "shit") {
        await accountInfo.TokcuntsInstance.methods
          .getShit(accountInfo.account, tokensSelection)
          .send({
            from: accountInfo.account,
          });
      } else if (selection.toLowerCase() === "diamond") {
        await accountInfo.TokcuntsInstance.methods
          .getDiamond(accountInfo.account, tokensSelection)
          .send({
            from: accountInfo.account,
          });
        accountInfo.refreshContract();
        delay(3000);
        window.location.reload();
      } else {
        displayAlert("Invalid selection");
      }
      displayAlert("Claim successful!", "success");
    } catch (error) {
      displayAlert(error.message);
    }

    accountInfo.updateAccountInfo({ userFeedback: null });
  }

  function renderUserFeedback() {
    if (accountInfo.userFeedback) {
      return (
        <React.Fragment>
          <div>
            <Spinner animation="grow" variant="light" />
          </div>
          <div>{accountInfo.userFeedback}</div>
        </React.Fragment>
      );
    }
  }

  function renderLogo() {
    if (selection.toLowerCase() === "shit") {
      return <Image src={shitCunt} height={"400px"}></Image>;
    } else if (selection.toLowerCase() === "diamond") {
      return <Image src={goldCunt} height={"400px"}></Image>;
    } else {
      return <Image src={logo} height={"400px"}></Image>;
    }
  }

  return (
    <Container className="mb-5">
      <Row style={{ fontWeight: "bold" }}>
        <Col className="title_font">TOKCUNTS</Col>
      </Row>
      <Row>
        <Col>
          Here you can circulate tokcunts to claim limited edition Shitcoin and
          Diamond Tokcunts. <br />
          Each tokcunt can be circulated once, including limited edition
          tokcunts. Circulated tokcunts are not destroyed; instead they appear
          aged to show they’ve been used. Circulate 3 tokcunts to earn a
          Shitcoin, and 11 to earn a Diamond. <br />
          Circulate your Diamond to earn a custom liperation biker vest and a
          personal phone call from our founder Frida Future.
        </Col>
      </Row>
      <Row className="d-flex xs_center mt-5">
        <Col
          xs={12}
          lg={6}
          className="d-flex justify-content-center align-items-center"
        >
          {renderLogo()}
        </Col>
        <Col
          xs={12}
          lg={6}
          className="justify-content-center align-items-center text-align-center "
        >
          {renderUserInterface()}
          {renderUserFeedback()}
          {renderAlert()}
        </Col>
      </Row>
    </Container>
  );
}

export default Claim;
