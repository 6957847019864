import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Home from "./Components/Home";
import Claim from "./Components/Claim";
import NavigationBar from "./Components/Navigationbar.js";
import AccountInfoProvider from "./Context/AccountInfo";
import { Routes, Route } from "react-router-dom";
import "./App.css";

function App() {
  return (
    <AccountInfoProvider>
      <div className="background">
        <NavigationBar />
        <div className="App d-flex align-items-center justify-content-center">
          <Container>
            <Row
              id="App_row"
              className="d-flex align-items-center justify-content-center mb-5"
            >
              <Col className="d-flex align-items-center justify-content-center">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/circulate" element={<Claim />} />
                </Routes>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </AccountInfoProvider>
  );
}

export default App;
