import React, { Component } from "react";
import Web3 from "web3";
import { Button } from "react-bootstrap";
import { AccountInfoContext } from "../Context/AccountInfo";
import Tokcunts from "../contracts/Tokcunts.sol/Tokcunts.json";
import TokcuntsMint from "../contracts/TokcuntsMint.sol/TokcuntsMint.json";
import * as contractsData from "../contracts/contractsData.json";
import { Alchemy, Network } from "alchemy-sdk";

class Connect extends Component {
  static contextType = AccountInfoContext;

  componentDidMount = async () => {
    if (window.ethereum) {
      this.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      this.web3 = new Web3(window.web3.currentProvider);
    } else {
      var provider = `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID1}`;
      var web3Provider = new Web3.providers.HttpProvider(provider);
      this.web3 = new Web3(web3Provider);
    }
    this.context.updateAccountInfo({ web3: this.web3 });
    if (this.web3) {
      await this.setNetwork();
      await this.getContractsInstances();
      if (window.ethereum || window.web3) {
        await this.setAccount();
      }
    }
  };

  async getContractsInstances() {
    this.networkId = await this.web3.eth.getChainId();
    this.context.updateAccountInfo({ networkId: this.networkId });

    this.TokcuntsInstance = new this.web3.eth.Contract(
      Tokcunts.abi,
      contractsData.mainnet.Tokcunts.proxy
    );

    this.TokcuntsMintInstance = new this.web3.eth.Contract(
      TokcuntsMint.abi,
      contractsData.mainnet.TokcuntsMint.contract
    );

    this.context.updateAccountInfo({
      TokcuntsInstance: this.TokcuntsInstance,
      TokcuntsMintInstance: this.TokcuntsMintInstance,
    });
    this.getTokcuntsInfo();
    this.getTokcuntsMintInfo();
    this.context.updateAccountInfo({
      instancesLoaded: true,
      refreshContract: this.refreshContract,
    });
  }

  async setAccount() {
    if (this.context.networkId !== null) {
      let accounts = await this.web3.eth.getAccounts();
      await this.context.updateAccountInfo({ account: accounts[0] });
      if (this.context.account) this.getAccountsData(accounts[0]);
    } else {
      this.resetAccountData();
    }
  }

  resetAccountData() {
    this.context.updateAccountInfo({
      account: null,
    });
  }

  async setNetwork() {
    let networkId = await this.web3.eth.getChainId();
    this.context.updateAccountInfo({ networkId: networkId });
  }

  async getAccountsData() {
    if (
      this.context.networkId === parseInt(process.env.REACT_APP_MAINNET_NETWORK)
    ) {
      this.context.updateAccountInfo({
        walletETHBalance: await this.web3.eth.getBalance(this.context.account),
      });
      this.getTokcuntsNFTs();
    }
  }

  async refreshContract() {
    const settings = {
      apiKey: process.env.REACT_APP_ALCHEMY_KEY,
      network: Network.ETH_MAINNET,
    };

    const alchemy = new Alchemy(settings);
    alchemy
      .refreshContract(contractsData.mainnet.Tokcunts.proxy)
      .then(console.log);
  }

  async getTokcuntsNFTs() {
    if (
      this.context.networkId === parseInt(process.env.REACT_APP_MAINNET_NETWORK)
    ) {
      const config = {
        apiKey: process.env.REACT_APP_ALCHEMY_KEY,
        network: Network.ETH_MAINNET,
      };
      const alchemy = new Alchemy(config);
      const response = await alchemy.nft.getNftsForOwner(this.context.account, {
        contractAddresses: [`${contractsData.mainnet.Tokcunts.proxy}`],
      });
      this.context.updateAccountInfo({
        nfts: response.ownedNfts,
        nftsLoaded: true,
      });
    }
  }

  async getTokcuntsInfo() {
    if (
      this.context.networkId === parseInt(process.env.REACT_APP_MAINNET_NETWORK)
    ) {
      let shitUnlocked = await this.TokcuntsInstance.methods
        .shitUnlocked()
        .call();
      let diamondUnlocked = await this.TokcuntsInstance.methods
        .diamondUnlocked()
        .call();
      this.context.updateAccountInfo({
        shitUnlocked: shitUnlocked,
        diamondUnlocked: diamondUnlocked,
      });
    }
  }
  async getTokcuntsMintInfo() {
    if (
      this.context.networkId === parseInt(process.env.REACT_APP_MAINNET_NETWORK)
    ) {
      let dropOpened = await this.TokcuntsMintInstance.methods
        .mintOpened()
        .call();
      let price = await this.TokcuntsMintInstance.methods.price().call();
      this.context.updateAccountInfo({ dropOpened: dropOpened, price: price });
    }
  }

  async connectWallet() {
    this.context.updateAccountInfo({ transactionInProgress: true });
    try {
      window.ethereum.enable();
    } catch (error) {
      console.log(error);
    }
    this.context.updateAccountInfo({ transactionInProgress: false });
  }

  getAccountStr(account) {
    let response =
      account.slice(0, 5) + "..." + account.substring(account.length - 2);
    return response;
  }

  renderUserInterface() {
    if (!this.context.account) {
      return (
        <Button
          variant="outline-light"
          className="interface_button"
          onClick={() => this.connectWallet()}
        >
          Connect
        </Button>
      );
    } else if (
      parseInt(this.context.networkId) !==
      parseInt(process.env.REACT_APP_MAINNET_NETWORK)
    ) {
      return (
        <p style={{ color: "white" }}>
          Please connect to{" "}
          {parseInt(process.env.REACT_APP_MAINNET_NETWORK) === 1
            ? "Ethereum MAINNET"
            : "the GOERLI Network"}
        </p>
      );
    } else
      return (
        <Button
          variant="outline-light"
          id="interface_connection"
          className="interface_button"
        >
          Connected as {this.getAccountStr(this.context.account)}
        </Button>
      );
  }

  render() {
    if (window.ethereum || window.web3) {
      if (this.web3) {
        window.ethereum.on("accountsChanged", async () => {
          await this.setAccount();
        });
        window.ethereum.on("chainChanged", async () => {
          await this.setNetwork();
          await this.setAccount();
        });
      }
    }
    return this.renderUserInterface();
  }
}

export default Connect;
