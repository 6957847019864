import React, { useState, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Alert,
  Button,
  Image,
  Form,
  Spinner,
} from "react-bootstrap";
import { AccountInfoContext } from "../Context/AccountInfo.js";

import "../App.css";
import logo from "../images/LogoTokcunts.png";

function Home() {
  let accountInfo = useContext(AccountInfoContext);
  const [alert, setAlert] = useState({
    active: false,
    content: null,
    variant: null,
  });
  const [quantity, setQuantity] = useState(1);

  function displayAlert(message, variant) {
    setAlert({ active: true, content: message, variant: variant });
    setTimeout(function () {
      setAlert({ active: false, content: null, variant: null });
    }, 10000);
  }

  function renderAlert() {
    if (alert.active) {
      return (
        <Col className="m-2">
          <Alert variant={alert.variant}>{alert.content}</Alert>
        </Col>
      );
    }
  }

  function handleQuantityChange(event) {
    let availableSupply = 1111 - accountInfo.availableSupply;
    if (parseInt(event.target.value) < 0) {
      setQuantity(0);
    } else if (parseInt(event.target.value) > availableSupply) {
      setQuantity(availableSupply);
    } else {
      setQuantity(event.target.value);
    }
  }

  function renderUserInterface() {
    if (!accountInfo.account) {
      return <div className="mt-5">Please connect your wallet</div>;
    }
    if (accountInfo.dropOpened) {
      let balanceInsufficient =
        accountInfo.walletETHBalance < accountInfo.price * quantity;
      return (
        <>
          <Row className="mt-5">
            <Col className="d-flex align-items-left justify-content-center m-2">
              <Form id="quantity_form">
                <Form.Group className="mb-3" controlId="quantity">
                  <Form.Label>Quantity</Form.Label>
                  <Form.Control
                    type="number"
                    value={quantity}
                    onChange={(e) => handleQuantityChange(e)}
                  />
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="d-flex align-items-left justify-content-center m-2">
              <Button
                id="mint_button"
                onClick={handleMint}
                disabled={balanceInsufficient}
              >
                {balanceInsufficient
                  ? "Insufficient funds"
                  : `Mint for ${
                      (accountInfo.price * quantity) / Math.pow(10, 18)
                    } ETH`}
              </Button>
            </Col>
          </Row>
        </>
      );
    } else {
      return <div className="mt-5">Drop Currently closed</div>;
    }
  }

  async function handleMint() {
    console.log(accountInfo);
    try {
      accountInfo.updateAccountInfo({ userFeedback: "Minting..." });
      await accountInfo.TokcuntsMintInstance.methods.mint(quantity).send({
        from: accountInfo.account,
        value: accountInfo.price * quantity,
      });
      accountInfo.updateAccountInfo({ userFeedback: null });
      displayAlert("Mint successful!", "success");
    } catch (error) {
      displayAlert(error.message);
    }
  }

  function renderUserFeedback() {
    if (accountInfo.userFeedback) {
      return (
        <React.Fragment>
          <div>
            <Spinner animation="grow" variant="light" />
          </div>
          <div>{accountInfo.userFeedback}</div>
        </React.Fragment>
      );
    }
  }

  return (
    <Container className="mb-5">
      <Row style={{ fontWeight: "bold" }}>
        <Col className="title_font">TOKCUNTS</Col>
      </Row>
      <Row className="d-flex xs_center mt-5">
        <Col
          xs={12}
          lg={6}
          className="d-flex justify-content-center align-items-center"
        >
          <Image src={logo} height={"400px"}></Image>
        </Col>
        <Col
          xs={12}
          lg={6}
          className="d-flex justify-content-center align-items-center text-align-center "
        >
          Tokcunts are the currency of CUNTRYCLUB. Rather than presidents’
          faces, our currency is adorned with the vulva of our founding
          matriarch. <br />
          <br />
          Fuck commodifying sexuality; lets sexualize commodities. Sex-negative
          culture takes our power, makes it taboo, and then profits from the
          illicit tension. Let’s take the taboo, make it power, and profit from
          its propagation together. <br />
          <br />
          Pleasure matters. True bodily excitement is for all of us. Cunt
          culture is a stand against rape culture, against the trauma responses
          we’ve all learned to cope with unwanted pressure from the world and
          the logic that there are things we MUST do, even if we don’t want to.
          It is a stand against scarcity thinking and zero sum games - there is
          more than enough for all of us.
          <br />
          <br />
          Each NFT is a unique, talismanic digital art object, which can be
          utilized within our Cuntmunity to earn swag or experiences.
        </Col>
      </Row>
      {renderUserInterface()}
      {renderUserFeedback()}
      <Row className="d-flex xs_center">{renderAlert()}</Row>
    </Container>
  );
}

export default Home;
